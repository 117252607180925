import rpc from "@/src/rpc"
import { useEffect, useState } from "react"
import { useAppRouter } from "@/src/useAppRouter"
import LoadingSpinner from "./LoadingSpinner"
import { $, pluralCount } from "@/lib/string"


export default function NewCustomerForm ({user, onClose, demo}) {

  const router = useAppRouter()

  const canCreateDemos = user && user.canCreateDemos
  const canCreateCustomers = user && user.canCreateCustomers
  const allowBlankPromoCode = user && !user.partnerId

  const accessAllowed = (
    (demo  && canCreateDemos) ||
    (!demo && canCreateCustomers)
  )

  const [currentPromo, setCurrentPromo] = useState(null)
  const [promos, setPromos] = useState(null)

  const showPromos = promos && promos.length > 1
  const canSubmit = allowBlankPromoCode || currentPromo

  useEffect(() => {
    if (!accessAllowed) return

    loadPromoCodes()

    async function loadPromoCodes() {
      const promoList = await rpc('promoUrls.all')
      if (!promoList) return
      const nonJazzlinkPromos = promoList.filter(p => !p.code.endsWith('-jazzlink'))
      if (!allowBlankPromoCode) {
        if (nonJazzlinkPromos.length > 0) setCurrentPromo(nonJazzlinkPromos[0])
      }
      setPromos(nonJazzlinkPromos)
    }
  }, [])

  async function createCustomer() {
    const response = await rpc('orgs.create', {
      promoCode: currentPromo?.code,
      demo,
    })
    if (response?.org) {
      const {org} = response
      router.push(`/orgs/${org.id}`)
    } else {
      console.error(response)
      alert('Error creating customer')
    }
  }

  if (!accessAllowed) {
    return <>
      <div className="p-5">
        <h2 className='text-center text-xl bold'>
          Sorry
        </h2>

        <p className="">
          You do not have access to this feature.
        </p>

        { !!onClose &&
          <div className="button-container mt-4">
            <button className='button' type="button" onClick={onClose}>
              Close
            </button>
          </div>
        }
      </div>
    </>
  }

  return <>
    <div className='content p-5 max-width-400'>
      <h2 className='text-center'>
        {demo ? 'Create Demo Account' : 'Create New Account'}
      </h2>
      { demo ?
        <p className='gray text-sm'>
          Demo accounts can't publish posts or send emails, and they only
          connect to <b>fake</b> social media accounts.
        </p>
      :
        <p className='gray text-sm'>
          Build a brand new social media calendar.
        </p>
      }

      <form className='mb-2 flex-col gap-2' onSubmit={createCustomer}>
        { !promos ?
          <LoadingSpinner size='small'/>
        : promos.length === 0 ?
          <div className='red'>Error: No promo codes found.</div>
        :
          <div className='field'>
            { showPromos &&
              <div className="mb-2">
                <label className='label'>
                  Promo Code
                </label>
                <div className='control full-width'>
                  <div className='select full-width'>
                    <select className='full-width' value={currentPromo?.id} onChange={e => setCurrentPromo(promos.find(r => r.id == e.target.value))}>
                      { allowBlankPromoCode &&
                        <option value=''>(none)</option>
                      }
                      { promos && promos.map(p => {
                        return <option key={p.id} value={p.id}>{p.code}</option>
                      })}
                    </select>
                  </div>
                </div>
              </div>
            }
            { (showPromos && !demo) &&
              <div className="flex-row gap-1 gray text-sm flex-center">
                { currentPromo?.percent > 0 ?
                  <span>
                    {currentPromo.percent}% off {
                      currentPromo.months
                      ? <span className='subtle note'> (for {pluralCount(currentPromo.months, 'month')})</span>
                      : ''
                    }
                  </span>
                :
                  <span>
                    &nbsp;
                  </span>
                }
              </div>
            }
          </div>
        }
      </form>

      <div className='button-container mt-4'>
        <button className='button is-primary' onClick={createCustomer} disabled={!canSubmit}>
          Start Signup
        </button>
        { !!onClose &&
          <button className='button' type="button" onClick={onClose}>
            Cancel
          </button>
        }
      </div>
    </div>

    <style jsx>{`
      hr {
        margin: 1rem 0;
      }
    `}</style>
  </>
}
